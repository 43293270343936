@import "./client/common/globalStyling/letterStyling.css";

html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

div#root {
  height: 100%;
}

.ql-editor {
  min-height: 100px;
}

.ql-container {
  max-height: 600px;
  overflow: scroll;
}

.MuiDialog-paper .ql-container {
  max-height: 175px;
}

.letter-preview p,
.quill-editor-overrides p {
  margin: 0;
}
